window.handle_flatpicker = function (idElement, name, config = {}) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  let datePickerString = params.get(name);

  let datePicker = flatpickr(`#${idElement}`, {
    dateFormat: config.enableTime ? "Y/m/d H:i" : "Y/m/d",
    locale: "ja",
    enableTime: config.enableTime || false,
    time_24hr: config.time_24hr || false,
    minuteIncrement: 1,
    onChange: function (selectedDates, dateStr, instance) {
      const input = document.getElementById(idElement);
      if (selectedDates.length === 0) {
        input.value = "";
      } else {
        if (config.enableTime) {
          input.value = moment(dateStr, "YYYY/MM/DD HH:mm").format(
            "YYYY/MM/DD HH:mm"
          );
        } else {
          input.value = moment(dateStr, "YYYY/MM/DD").format("YYYY/MM/DD");
        }
      }
    },
    onReady: function () {
      const input = document.getElementById(idElement);
      input.dataset.initialValue = input.value || "";
    },
  });

  if (datePickerString) {
    datePicker.setDate(datePickerString, true);
  }

  const formElement = document.getElementById(idElement).closest("form");
  if (formElement) {
    formElement.addEventListener("reset", function () {
      const input = document.getElementById(idElement);
      const initialValue = input.dataset.initialValue || "";
      if (initialValue) {
        datePicker.setDate(initialValue, true);
      } else {
        datePicker.clear();
      }
    });
  }
};

$(document).ready(function () {
  $("form[id='delete']").on("submit", function (e) {
    e.preventDefault();
    var submitBtn = $(document).find('button[id="delete"]');
    submitBtn.prop("disabled", true);
    $(this).off("submit").submit();
  });
});

window.getParsedContentAttribute = (content) => {
  let parser = new DOMParser();
  let doc = parser.parseFromString(content, "text/html");

  // Process <img> tags
  doc.querySelectorAll("img").forEach((img) => {
    console.log(img)
    let dataPath = img.getAttribute("data-path");
    if (dataPath) {
      img.setAttribute("src", `/file/${dataPath}`);
    }
  });

  // Process <a> tags
  doc.querySelectorAll("a").forEach((a) => {
    let dataPath = a.getAttribute("data-path");
    if (dataPath) {
      a.setAttribute("href", `/file/${dataPath}`);
      a.setAttribute("target", "_blank");
    }
  });

  return doc.body.innerHTML;
};
